/* Navbar container */
.navbar {
  background-color:white;
  color: #fff;
  padding: 10px;
}

/* Navbar row */
.navbar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar mobile row */
.navbar-mobile-row {
  display: none;
}

/* Navbar logo */
.navbar-logo {
  font-size: 24px;
}

/* Navbar items */
.navbar-item {
  margin-right: 10px;
}

/* Navbar dropdown */
.navbar-dropdown {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

/* Navbar item on mobile */
.navbar-mobile-item {
  display: none;
}

/* Dropdown container */
.navbar-dropdown {
  display: flex;
}

/* Dropdown item */
.navbar-dropdown-item {
  position: relative;
  margin-right: 20px;
}

/* Dropdown menu */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
}

/* Dropdown menu items */
.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show dropdown menu on hover */
.navbar-dropdown-item:hover .dropdown-content {
  display: block;
}

/* Adjust position of dropdown menu */
.dropdown-content {
  top: 30px; /* Adjust as needed */
  right: 0; /* Adjust as needed */
}

/* YourComponent.css */


/* Media query for mobile */
@media (max-width: 768px) {
  /* Hide desktop items */
  .navbar-search,
  .navbar-shop,
  .navbar-cart,
  .navbar-login {
    display: none;
  }

  /* Show mobile row */
  .navbar-mobile-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }

  /* Show mobile items */
  .navbar-mobile-item {
    display: block;
    margin-right: 10px;
  }
}


/* new */
.search-container {
  position: relative;
}

.search-icon {
  cursor: pointer;
}

.search-bar {
  position: absolute;
  right: 0;
  top: 0px;
  width: 0;
  /* height: 40px; */
  background-color: white;
  border: 0.5px solid #f8f4f4;
  border-radius: 4px;
  padding: 0 10px;
  transition: width 0.3s ease;
  opacity: 0;
}

.search-bar.expanded {
  width: 250px; /* Adjust width as needed */
  opacity: 1;
}

.search-bar:focus {
  outline: none;
}

/* Adding hover effect for navbar items */
.nav-link:hover {
  color: green !important; /* You can use any green shade you prefer */
  transition: color 0.3s ease; /* Smooth transition */
}