body{
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 115%;
  /* background-color: rgba(211, 211, 211, 0.217); */
  overflow-x: hidden;
}
.nav-item {
  color: black;
  /* font-weight: bold; */
}

.nav2 {
  display: none;
}

@media (max-width:768px) {
  .nav2 {
    display: block;
  }

  .nav1 {
    display: none;
  }

  .NavLogo {
    width: 100px;
    height: auto;
  }
}

@media (max-width:667px) {
  .nav_gap {
    gap: 0px;
  }
}


@media (max-width:667px) {
  .fa-ellipsis-vertical {
    display: none;
  }
}

@media (max-width:667px) {
  .form-inline {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-toggle {
  border-radius: 0;
  border: 0;
}

.dropdown-toggle:hover {
  border-top: 2px solid green;
}

@keyframes slideInRight2 {
  from {
    transform: translateX(-1000px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight3 {
  from {
    transform: translateX(-1000px);
  }

  to {
    transform: translateX(0);
  }
}

.about_area {
  margin: 50px;
}

.icon_area {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  background-color: white;
  color: black;
  justify-content: center;
}

.icon_area1 {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  background-color: white;
  color: rgb(25, 150, 25);
  justify-content: center;
}

/* About  */
.about_img {
  width: 350px;
  margin-top: 20px;
  margin-left: -180px;
  border-radius: 10px;
}

/* Services */
.service_area {
  background-color:white ;
  width: 100%;
  padding: 20px;
  /* height: 120px; */
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Sign in up */
.login_btn {
  border: 2px solid rgb(25, 150, 25);
}

.sign_upbtn {
  background-color: rgb(58, 210, 58);
  padding: 10px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sign_upbtn:hover {
  background-color: rgb(21, 49, 21);
  color: white;
}

.login_img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.app_text {
  color: white;
  position: absolute;
  bottom: 0;
  font-size: medium;
}

.sign_up_btn {
  transform-style: preserve-3d;
  background: linear-gradient(blue, rgb(10, 10, 176));
  width: 150px;
}

.sign_up_btn:hover {
  font-size: 17px;
}

/* Register.css */

.con {
  margin: 0;
  /* style={{fontSize:20 , fontFamily:"Tahoma, Verdana, sans-serief", fontWeight:"normal"}} */
  /* font-family: Tahoma, verdana, sans-serief; */
  /* font-family: 'Arial', sans-serif; */
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  /* background-color: yellowgreen */
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

select {
  background-color: transparent;
  padding: 10px;
  border: 0.1rem solid green;
  border-radius: 0.4rem;
  width: 100%;
  font-size: 1rem;
  outline: none;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 2rem;
  padding: 3rem 5rem;
  width: 40%;
  /* Adjust the width here */
  max-width: 500px;
  /* Add max-width to limit width on larger screens */
}

.brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.brand img {
  height: 7rem;
}

.brand h1 {
  color: black;
  text-transform: uppercase;
  margin: 0;
}

input {
  background-color: transparent;
  padding: 10px;
  border: 0.1rem solid green;
  border-radius: 0.4rem;
  width: 100%;
  font-size: 1rem;
  outline: none;
}

input:focus {
  border: 0.1rem solid black;
}

.login_btn {
  background-color: yellowgreen;
  color: white;
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.4rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.login_btn:hover {
  background-color: rgb(68, 92, 21);
}

span {
  color: white;
  text-transform: uppercase;
  margin-top: 1rem;
}

span a {
  color: #4e0eff;
  text-decoration: none;
  font-weight: bold;
}

span a:hover {
  text-decoration: underline;
}

/* slider Outerdiv */
.arrow-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  /* Adjust as needed */
  height: 30px;
  /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #101010c4;
  /* Background color for the arrow container */
  color: #f3eeee;
  border-radius: 0.5rem;
  /* Text color for the arrow */
}

.prev {
  left: -40px;
  z-index: 10;
  background-color: transparent;
  color: black;
  /* Adjust as needed */
}

.next {
  right: -40px;
  background-color: transparent;
  color: #131324;
  /* Adjust as needed */
}

.category_btn_area {
  display: flex;
  gap: 10px;
  margin: auto;
}

.searchBar {
  margin: auto;
}

.outerDiv {
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  padding: 30px;
  background-color: rgb(247, 247, 247);
}

.product_div {
  height: auto;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 10px;
  margin-top: 0;
}

.product_div {
  transition: transform 0.3s ease;
}

.product_div:hover {
  transform: scale(1.05);
}

.image_div {
  height: 180px;
  width: 150px;
  padding: 0px;
}

.details_div {
  width: 150px;
  height: 60px;
  text-align: center;
  padding: 5px;
}

.sec.animate {
  transform: translateX(-100%);
}

.sec.show-animate .animate {
  transform: translateX(0);
}

section .animate {
  transition: 1s;
}


.password-input-container {
  position: relative;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  padding-right: 30px;
  /* Space for the icon */
}

.password-toggle {
  position: absolute;
  top: 25%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.c2_area {
  animation-name: border_ani;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-iteration-count: 100;
  animation-timing-function: ease;

}

@keyframes border_ani {
  0% {
    border-top-left-radius: 50px;
  }

  25% {
    border-top-right-radius: 50px;
  }

  50% {
    border-bottom-right-radius: 50px;
  }

  75% {
    border-bottom-left-radius: 50px;
  }

  100% {
    border-top-left-radius: 50px;
  }
}

/* style.css */
.searchBarContainer {
  display: flex;
  align-items: center;
}

.searchBar {
  flex: 1;
  margin-right: 10px;
}

.orderCard {
  margin-bottom: 20px;
}

.orderCardBody {
  padding: 20px;
}

.orderTitle {
  font-size: 24px;
  margin-bottom: 10px;
  color: green;
}

.orderInfo {
  font-size: 16px;
  margin-bottom: 10px;
}

.productList {
  list-style-type: none;
  padding: 0;
}

.productItem {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.productImage {
  width: 100px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
}

.productDetails {
  flex: 1;
}

.productTitle {
  font-size: 18px;
  margin-bottom: 5px;
}

.productDescription {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.productPrice {
  font-size: 16px;
  font-weight: bold;
}

.productQuantity {
  font-size: 14px;
}

.productStatus {
  font-size: 14px;
  color: #ff0000;
  /* red color for status */
}

@media screen and (max-width: 768px) {
  .searchBarContainer {
    flex-direction: column;
  }

  .searchBar {
    margin-bottom: 10px;
  }
}

/* Style for search suggestions */
.search-suggestions {
  position: absolute;
  top: 100%;
  left: -235px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 20px;
  width: 400px;
  max-height: 300px; /* Adjust based on how much space you want to allow before scrolling */
  overflow-y: auto; /* Adds vertical scrolling */
}

.search-suggestion {
  padding: 10px;
  cursor: pointer;
}

.search-suggestion:hover {
  background-color: #f0f0f0;
}

.c1 {
  background-color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.about_img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  margin-left: 170px;
}

.c1:hover {
  transform: scale(1.1);
}

.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09) !important;
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  color: #fff;
}

.l-bg-yellow-dark {
  background: linear-gradient(to right, yellowgreen, yellow) !important;
  color: #fff;
}

.l-bg-red-dark {
  background: linear-gradient(to right, red, orange) !important;
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
}

.l-bg-brown-dark {
  background: linear-gradient(to right, gray, lightgray) !important;
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56) !important;
  color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas,
.card .card-statistic-3 .card-icon-large .far,
.card .card-statistic-3 .card-icon-large .fab,
.card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card .card-statistic-3 .card-icon {
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: 5px;
  top: 20px;
  opacity: 0.1;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.zoom-image {
  width: 100%;
  height: 100%;
  animation: zoom-animation 10s ease-in-out infinite;
}

.cart-count-badge {
  top: -30px;
  right: 5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@keyframes zoom-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Journey */
#journey {
  padding: 50px 0;
}

/* Style the section title */
.section-title {
  color: #333;
  font-size: 2.5rem;
  font-weight: bold;
}

/* Style the journey text */
.journey-text {
  font-family: Verdana, Tahoma, sans-serif;
}

/* Style the journey image */
.journey-image {
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* Scale the image on hover */
.journey-image:hover {
  transform: scale(1.05);
}

/* Style the container */
.container {
  position: relative;
}

/* Add a pseudo-element for decorative purposes */
.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: -1;
}

/* Style the journey text wrapper */
.journey-section {
  background-color: white;
  padding: 30px;
  text-align:justify;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.journey-text {
  margin: 0 auto 30px;
}

.journey-text p {
  line-height: 1.6;
  height: auto;
}

.animated-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  position: relative;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.background-image-opacity {
  position: relative;
  overflow: hidden;
}

.background-image-opacity::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(230, 249, 230);
  background-size: cover;
  opacity: 0.7;
  z-index: -1;
}

/* Vision */
.vision-container {
  background-color: #f9f9f9;
  padding: 2rem 0;
}

.section-title {
  font-weight: bold;
  color: #4CAF50;
}

.vision-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
}

.vision-card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.card-text {
  color: #666;
  line-height: 1.6;
  font-size: 115%;
}

@media (max-width: 768px) {
  .vision-card {
    padding: 1.5rem;
  }
}

/* Servicess 3d */

.card_3d {
  width: 250px;
  height: 200px;
  perspective: 1000px;
  box-shadow: rgba(92, 211, 68, 0.15) 1.95px 1.95px 2.6px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.card_3d:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.card-back {
  transform: rotateY(180deg);
  color: white;
  /* font-size: 20; */
  background: linear-gradient(to right, #a8e063, #56ab2f);
 
}

.news_card {
  width: 75%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #b9b7b7e0;
  padding: 10px;
  border-radius: 10px;
}
.container {
  text-align: center;
  padding: 20px;
}

.heading {
  margin-bottom: 20px;
}

.subtext {
  margin-bottom: 40px;
}

.transparent-section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: rgba(80, 80, 245, 0.4);
}

.partition {
  flex: 1;
  padding: 30px;
  border: 1px solid white;
  margin: 0 10px;
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.middle-section {
  display: flex;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: linear-gradient(to bottom, skyblue, blue);
}

.left-part {
  flex: 1;
  padding: 20px;
}

.right-part {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.partition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ccc; */
  padding: 20px;
  text-align: center;
}

.partition img {
  width: 50%;
  height: auto;
  margin-bottom: 10px;
}

.partition h5 {
  margin: 0;
  font-size: 1em;
}

/* catgoriwise */
.container {
  width: 80%;
  margin: auto;
}

.heading {
  font-size: 30px;
  /* font-weight: bold; */
}

.subtext {
  font-size: 17px;
}

.transparent-section {
  border-radius: 20px;
  width: 100%;
  border-collapse: collapse;
  padding: 25px;
  background: linear-gradient(to bottom, lightgreen, green);

}

/* design */
.category-card {
  position: relative;
  overflow: hidden;
}

.category-card .card-header img {
  transition: opacity 0.3s ease;
}

.category-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-card:hover .overlay {
  opacity: 1;
}

.category-card:hover .card-header img {
  opacity: 0.8;
}

.overlay button {
  display: block;
  background-color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
/* certificate */
.rotate-img {
  transform: rotate(-90deg); /* Adjust the angle as needed */
  transform-origin: center; /* Adjusts the point around which the image rotates */
  margin-right: 400px;
  margin-top: -50px;
 
}
/* Display the dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* Ensure dropdown closes properly when not hovered */
.dropdown .dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 0;
  z-index: 1000;
}

/* Optional: Style adjustments */
.dropdown-item:hover {
  background-color: #f8f9fa;
}

.custom-dropdown-toggle {
  color: black;
  background-color: transparent;
  border: none;
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus {
  color: black;
  background-color: transparent;
  box-shadow: none;
}

/* footer */
h6 {
  font-size: 16px;
  font-weight: bold;
}

.icon_area {
  cursor: pointer;
  padding: 10px;
  color:black;
}

ul li a:hover {
  color: #ddd;
}

.text-uppercase {
  letter-spacing: 1px;
  padding-left: 40px;
}

/* sidebar */
.sidebar-fixed-height {
  height: 400px;
  overflow-y: auto; 
margin-top: 25px;
}

/* perks */
.perks-card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.perks-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #f9f9f9;
}

.perks-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.perks-icon {
  font-size: 50px;
  color: green; /* Light blue color for icons */
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.card-body {
  padding: 2rem;
}

@media screen and (max-width: 576px) {
  .p-5 {
    padding: 20px !important;
    
  }

  .col-md-6, .col-md-7 {
    width: 100%;
  }

  .btn-lg {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* For larger screens */
@media screen and (min-width: 992px) {
  .map-container iframe {
    height: 400px;
  }
}

/* Default margin for large screens */
.category-card {
  margin: 30px;
}

@media (max-width: 768px) {
  .category-card {
    margin: 10px;
  }
}

@media (min-width: 992px) {
  .padding-lg-left {
      padding-left: 90px; 
  }
}

/* journey  */
@media (max-width: 1200px) {
  .journey-text h4 {
    font-size: 20px;
  }
  .journey-text p {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .journey-text h4 {
    font-size: 18px;
  }
  .journey-text p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  /* Small screens */
  .journey-text h4 {
    font-size: 18px;
  }
  .journey-text p {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .journey-text h4 {
    font-size: 12px;
  }
  .journey-text p {
    font-size: 12px;
  }
}


/* Harnessing */
.harnessing-section {
  box-shadow: 0 -4px 20px 0 #90d576;
}

.heading-text {
  font-size: 115%;
}

.description-text {
  font-size: 115%;
}

.card-text {
  font-size: 115%;
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 1.5rem; 
  }

  .description-text {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .heading-text {
    font-size: 1rem; /* Even smaller font size for mobile screens */
  }

  .description-text {
    font-size: 0.9rem;
  }

  .card-text {
    font-size: 0.8rem;
  }
}


/* services */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: white;
}

.card img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.card h6 {
  font-size: 1.25rem;
  margin-top: 15px;
  text-align: center;
  color: #333;
}

.card-text {
  font-size: 1rem;
  text-align: center;
  color: #666;
  padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
      padding: 1rem;
  }

  .card img {
      width: 75%;
  }

  .card h6 {
      font-size: 1.1rem;
  }

  .card-text {
      font-size: 0.9rem;
  }
}

/* 3D Flip Card Specific Styles */
.card_3d {
  perspective: 1000px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

/* .card-front {
  background-color: #fff;
}

.card-back {
  background-color: #f7f7f7;
  transform: rotateY(180deg);
  padding-top: 50px; 
} */

.card-back p {
  font-size: 0.9rem;
  color: #444;
}

/* Responsive adjustments for 3D cards */
@media (max-width: 768px) {
  .card_3d .card-front,
  .card_3d .card-back {
      padding: 1rem;
  }

  .card-back p {
      font-size: 0.85rem;
  }
}

/* Responsive alignment for the second row */
@media (max-width: 768px) {
  .offset-lg-3 {
      margin-left: 0 !important; /* Disable the offset on smaller screens for better alignment */
  }
}

/* Design */
/* General card styles */
.category-card {
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  background-color: white;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Image and overlay styles */
.category-card img {
  object-fit: cover;
  height: 200px;
  transition: transform 0.3s ease;
}

.category-card:hover img {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-card:hover .overlay {
  opacity: 1;
}

/* Button inside the overlay */
.overlay .btn {
  padding: 10px 20px;
  font-size: 1rem;
  color: #333;
  background-color: #fff;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.overlay .btn:hover {
  background-color: #90d576;
  color: white;
}

/* Text inside the card */
.category-card .card-body h4 {
  font-size: 1.2rem;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-card {
      margin-bottom: 20px;
  }

  .category-card img {
      height: 150px;
  }

  .category-card .card-body h4 {
      font-size: 1rem;
  }

  .overlay .btn {
      font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .category-card img {
      height: 120px;
  }

  .category-card .card-body h4 {
      font-size: 0.9rem;
  }
}


/* Default font size */
.our-facts-title {
  font-size: 40px;
}

.our-facts-subtitle {
  font-size: 24px;
}

/* For screens smaller than 768px (tablets) */
@media (max-width: 768px) {
  .our-facts-title {
      font-size: 32px;
  }
  .our-facts-subtitle {
      font-size: 20px;
  }
}

/* For screens smaller than 576px (mobile phones) */
@media (max-width: 576px) {
  .our-facts-title {
      font-size: 28px;
  }
  .our-facts-subtitle {
      font-size: 18px;
  }
}

/* About Us */
.responsive-text {
  font-size: clamp(12px, 2vw, 22px); /* Adjust the minimum, preferred, and maximum sizes */
  line-height: 1.5;
}

/* styles.css or any relevant CSS file */
@media (max-width: 576px) {
  /* Styles for small screens (phones) */
  .card {
      font-size: 14px;
  }

  h3 {
      font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  /* Styles for medium screens (tablets) */
  .card {
      font-size: 16px;
  }

  h3 {
      font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /* Styles for large screens (laptops) */
  .card {
      font-size: 18px;
  }

  h3 {
      font-size: 28px;
  }
}

@media (min-width: 992px) {
  /* Styles for extra large screens */
  .card {
      font-size: 20px;
  }

  h3 {
      font-size: 32px;
  }
}
/* contact form */

.contact-section {
  font-size: 115%; /* Default font size */
}

/* Media queries for responsive font size */
@media (max-width: 768px) {
  .contact-section {
      font-size: 100%;
  }
}

@media (max-width: 576px) {
  .contact-section {
      font-size: 90%;
  }
}

@media (max-width: 400px) {
  .contact-section {
      font-size: 85%;
  }
}

/* Adjust form layout */
.contact-info-wrapper {
  background-color: #e0f7e7;
  box-shadow: 0 -4px 20px 0 #90d576;
}

.contact-info p {
  margin-bottom: 1.5rem;
}


/* perks card */
/* Default styling for larger screens */
.perks-card-deck .card-title {
  font-size: 1.5rem;
}

.perks-card .card-body i {
  font-size: 2.5rem;
}

.sidebar-fixed-height .col-12 {
  font-size: 1.2rem;
}

/* For medium screens (tablets, etc.) */
@media (max-width: 768px) {
  .perks-card-deck .card-title {
      font-size: 1.3rem;
  }

  .perks-card .card-body i {
      font-size: 2rem;
  }

  .sidebar-fixed-height .col-12 {
      font-size: 1.1rem;
  }
}

/* For small screens (mobile devices) */
@media (max-width: 576px) {
  .perks-card-deck .card-title {
      font-size: 1.1rem;
  }

  .perks-card .card-body i {
      font-size: 1.8rem;
  }

  .sidebar-fixed-height .col-12 {
      font-size: 1rem;
  }
}


/* Custom Styles */
.based-prod-sec {
  background-color: #f8f9fa;
}

.based-prod-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.based-prod-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.based-prod-card figure img {
  border-radius: 10px;
}

.based-prod-card a {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .based-prod-card {
    margin-bottom: 20px;
  }
}
/* Initial state */
.category-card {
  transform: scale(0.9);
  opacity: 0;
  transition: transform 1.5s ease, opacity 1.5s ease; /* Increased transition duration */
}

/* Zoom-in effect */
.category-card.zoom-in {
  transform: scale(1);
  opacity: 1;
}



/* TopCategories.css */

/* Zoom-in effect */
.top-cats-list-item {
  transition: transform 0.5s ease; /* Smooth transition for zoom */
}

.zoom-in-effect {
  transform: scale(1.1); /* Apply zoom effect when in view */
}

/* home */
/* Flexbox for arranging content in a row */
.content-bullet-block {
  display: flex;
  justify-content: space-between;
  gap: 16px; /* Adjust space between the cards */
}

.content-bullet-card {
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.content-bullet-card .icon img {
  width: 50px;
  height: 50px;
}

.content-bullet-card .ov-content {
  margin-top: 10px;
}

.content-bullet-card .ov-text {
  margin-top: 5px;
  font-size: 14px;
  color: #555;
}

.content-bullet-card .num {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
/* testinomals */
/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  /* background: linear-gradient(to right, #101c81, #2a6ba3); */
  min-height: 100vh;
}

.owl-carousel .owl-item {
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item .card {
  padding: 30px;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  overflow-y: auto !important;
  padding-bottom: 40px;
}

.owl-carousel .owl-item img {
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}

.owl-carousel .owl-item .card .name {
  position: absolute;
  bottom: -20px;
  left: 33%;
  color: #101c81;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: aquamarine;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  box-shadow: 2px 3px 15px #3c405a;
}

.owl-carousel .owl-item .card {
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item.active.center .card {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-theme .owl-dots .owl-dot span {
  height: 20px;
  background: #2a6ba3 !important;
  border-radius: 2px !important;
  opacity: 0.8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 13px;
  width: 13px;
  opacity: 1;
  transform: translateY(2px);
  background: #83b8e7 !important;
}

@media(min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
    left: 24%;
  }
}

@media(max-width: 360px) {
  .owl-carousel .owl-item .card .name {
    left: 30%;
  }
}
/* latest update */
/* Initial state for the fade-in and zoom-in */
.fade-in {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

/* When the element is visible, it will fade in and zoom in */
.fade-in-visible {
  opacity: 1;
  transform: scale(1);
}
/* design */
/* Initial state for the cards */
.card {
  transform: scale(0.8);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Active state for the cards */
.card.zoom-in {
  transform: scale(1);
  opacity: 1;
}
/* featured products */
/* Initial state for the cards */
.card {
  transform: scale(0.8);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Active state for the cards */
.card.zoom-in {
  transform: scale(1);
  opacity: 1;
}
/* Top categories */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.zoom-in-effect {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0.7;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
/* certified by */
.certified-sec .container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.certified-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
}

.certified-left, 
.certified-right {
  flex: 1;
  margin: 10px; /* Adds spacing for better layout */
}

.section-heading {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.certified-logo-slider {
  display: flex;
  flex-wrap: wrap; /* Ensures logos adjust for smaller screens */
  gap: 10px;
}

.certified-logo-slide {
  width: 120px; /* Set a fixed width */
  text-align: center;
}

.certified-logo-slide img {
  max-width: 100%;
  height: auto;
}

.consultant-image {
  position: relative;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://media.istockphoto.com/id/1311655328/photo/im-the-best-asset-in-my-business.jpg?s=612x612&w=0&k=20&c=ebkVt_iY6rRjXvyx2CESPC8EtcWrv0nYt_y4IdKmN3M=");
}

.advisory-serv-head {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  font-size: 1rem;
  color: red;
}

@media (max-width: 768px) {
  .certified-wrap {
    flex-direction: column;
  }

  .certified-logo-slider {
    justify-content: center;
  }

  .consultant-image {
    height: 300px; /* Adjust height for smaller screens */
  }

  .advisory-serv-head {
    font-size: 0.9rem; /* Scale down text */
  }
}

@media (max-width: 480px) {
  .section-heading {
    font-size: 1.5rem; /* Scale down heading */
  }

  .certified-logo-slide {
    width: 100px; /* Adjust logo size */
  }

  .advisory-serv-head {
    font-size: 0.8rem; /* Further scale down text */
  }
}
/* checkout */
.step-container {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
}

.step-body {
  padding: 10px;
}

/* career */
.flex-equal {
  flex: 1 1 200px; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 200px */
  max-width: 250px; /* Optional: Adjust the max-width */
}

.perks-container {
  gap: 16px; /* Adds uniform spacing between items */
}

.perks-item {
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.perks-icon {
  font-size: 40px;
  color: #388e3c;
}

.perks-item h5 {
  font-size: 16px;
  color: #333;
}
/* product page */
.product-description-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-description {
  padding: 10px;
  text-align: justify;
}
.product-image img {
  width: 100%;
  height: auto;
}

@media (max-width: 425px) {
  .product-description-container {
      flex-direction: column;
      align-items: center;
  }
  .product-description {
      width: 100%;
      padding: 10px;
  }
  .product-image {
      margin-bottom: 10px;
  }
  /* .btn {
      font-size: 12px;
      padding: 5px 8px;
      height: 28px;
  } */
}

@media (max-width: 375px) {
  .product-description-container {
      flex-direction: column;
      align-items: center;
  }
  .product-description {
      width: 100%;
      padding: 10px 5px;
  }
  .product-image {
      margin-bottom: 10px;
  }
  /* .btn {
      font-size: 12px;
      padding: 5px 8px;
      height: 26px;
  } */
  .modal-dialog {
      margin-top: 20%;
  }
}

@media (max-width: 320px) {
  .product-description-container {
      flex-direction: column;
      align-items: center;
  }
  .product-description {
      width: 100%;
      padding: 10px 5px;
  }
  .product-image {
      margin-bottom: 10px;
  }
  /* .btn {
      font-size: 12px;
      padding: 5px 8px;
      height: 26px;
  } */
  .modal-dialog {
      margin-top: 20%;
  }
}

/* footer */
.footer {
  background-color: #f8f9fa;
}

.footer h5 {
  font-size: 18px;
  margin-bottom: 1rem;
}

.footer p,
.footer ul li {
  font-size: 14px;
}

.footer ul {
  padding: 0;
  list-style-type: none;
}

.footer ul li {
  margin-bottom: 8px;
}

.copyright-sec {
  background-color: #343a40;
  color: #fff;
}

.copyright-sec a {
  color: #f8d90f;
  text-decoration: none;
}

.social-links a {
  margin: 0 5px;
  color: #fff;
  font-size: 20px;
}

.social-links a:hover {
  color: #f8d90f;
}

@media (max-width: 768px) {
  .footer h5 {
    font-size: 16px;
  }

  .footer p,
  .footer ul li {
    font-size: 12px;
  }

  .copyright-sec {
    text-align: center;
    flex-direction: column;
  }

  .social-links {
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .footer img {
    margin-bottom: 15px;
  }

  .footer ul {
    text-align: center;
  }
}
/* product details */
.product-details {
  /* padding: 20px; */
}
.product-image img {
  width: 100%;
  height: '100px';
  object-fit: cover;
}
.button-container {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* align:center; */
}
.image-container{
  height:'10px';
  width:'2px'
}
.product-info h3 {
  font-size: 1.5rem;
}

.category-badge {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 0.9rem;
}

.reviews-section button {
  margin-top: 10px;
}

textarea.form-control {
  resize: none;
  min-height: 80px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .product-details {
    /* padding: 15px; */
  }

  .product-image img {
    width: 100%;
    height: auto; 
  }

  .button-container {
    justify-content: center;
  }

  .product-info h3 {
    font-size: 1.2rem;
  }

  .product-info p {
    font-size: 0.9rem;
  }

  .reviews-section h6 {
    font-size: 1rem;
  }

  .modal-content {
    width: 90%;
  }
}

@media (max-width: 320px){
  .product-details {
    /* padding: 10px; */
  }

  .product-image img {
    height: 200px;
    object-fit: cover;
  }
  .product-image {
    align-items: center;
    padding: 10px;
  }
  /* .button-container {
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin-bottom:10px;
  } */
  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    width: 100%;
    align-items: center;
  }
  .product-info h3 {
    font-size: 1rem; 
  }

  .product-info p {
    font-size: 0.8rem;
    line-height: 1.2; /* Compact line spacing */
  }

  textarea.form-control {
    min-height: 60px; /* Reduce textarea height */
  }

  .reviews-section {
    margin-top: 10px;
  }

  .modal-content {
    height: auto; /* Ensure modal adjusts dynamically */
    padding: 10px;
  }
  .image-container {
    height: 200px;
    overflow: hidden;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    }
}
/* my orders */
/* Optional styling for the image wrapper */
.productImageWrapper {
  width: 100px;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
}

/* Styling for the product details */
.productDetails {
  flex-grow: 1;
  margin-left: 15px;
}

.productTitle {
  font-size: 16px;
  font-weight: bold;
}

.productDescription,
.productPrice,
.productQuantity,
.productStatus {
  font-size: 14px;
  color: #555;
}

.productStatus i {
  margin-right: 5px;
  font-size: 14px;
  color: #ff0000;
}
/* arrow */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1); /* Makes the arrow black */
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black !important;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
}


/* logo */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

.loading-logo {
  width: 150px;
  height: auto;
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}






